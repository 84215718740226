import {
  ColDef,
  GridApi,
  ColumnApi,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { computed, reactive, ref, onMounted } from "vue";

import { useStore } from "vuex";

import { getContractStatusName, setTempTenant } from "./ContractSetup";
import {
  formatNumbers,
  formatDates,
  translate,
  currentLang,
} from "@/core/helpers/config";
import AgGridLink from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridLink.vue";

export const gridApi = ref<GridApi>();

export const ContractTableSetup = (contractsArrayData) => {
  const store = useStore();
  const loading = ref(true);
  const colApi = ref<ColumnApi>();

  const bodyOptions = reactive({
    minColWidth: 100,
    editType: "",
    rowSelection: "single",
    enableRangeSelection: true,
    enableFillHandle: false,
    fillHandleDirection: "y",
    debounceVerticalScrollbar: true,
    onGridReady: (params) => {
      gridApi.value = params.api;
      colApi.value = params.columnApi;
    },
    autoGroupColumnDef: {
      headerName: translate("rfq.preferredTenant"),
      minWidth: 220,
      maxWidth: 200,
      menuTabs: [],
    },
    // optional as 'singleColumn' is the default group display type
    groupDisplayType: "groupRows",
  });

  const customColumnDefs = computed((): ColDef[] => {
    return [
      {
        headerName: translate("contractsPage.id"),
        field: "doc_no",
        editable: false,
        menuTabs: ["generalMenuTab"],
      },
      {
        headerName: translate("preferredTenant"),
        field: "tenantName",
        editable: false,
        rowGroup: true,
        hide: false,
        menuTabs: ["generalMenuTab"],
        valueGetter: (params: ValueGetterParams) => {
          return params.data.companyName;
        },
      },
      {
        headerName: translate("contractsPage.name"),
        field: "name",
        editable: false,
        menuTabs: ["generalMenuTab"],
      },
      {
        headerName: translate("contractsPage.status"),
        field: "status",
        editable: false,
        menuTabs: ["generalMenuTab"],
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value && params.value !== 0) return "";
          if (params.value === "##") return params.value;
          return translate(getContractStatusName(params.value));
        },
      },
      {
        headerName: translate("contractsPage.dueDate"),
        field: "cost_end",
        editable: false,
        menuTabs: ["generalMenuTab"],
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) return "";
          if (params.value === "##") return params.value;
          if (params.value === "0000-00-00")
            return translate("contractsPage.pending");
          return formatDates(params.value, "short", currentLang.value);
        },
      },
      {
        headerName: translate("contractsPage.budget"),
        field: "contract_budget",
        editable: false,
        menuTabs: ["generalMenuTab"],
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) return "";
          if (params.value === "##") return params.value;
          return `${formatNumbers(params.value)}`;
        },
      },
      {
        headerName: translate("contractsPage.actions"),
        colId: "action",
        editable: false,
        width: 50,
        pinned: "right",
        cellRendererFramework: AgGridLink,
        cellRendererParams: {
          clicked: function(params) {
            setTempTenant(params.data.tenant);
          },
        },
      },
    ];
  });

  onMounted(() => {
    setTimeout(() => {
      loading.value = false;
    }, 1500);
  });

  return {
    gridApi,
    colApi,
    loading,
    bodyOptions,
    customColumnDefs,
  };
};
