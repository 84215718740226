
import { defineComponent, computed } from "vue";
import CardSkeleton from "@/components/skeletons/CardSkeleton.vue"
import { getContractStatusName } from "@/views/contractor/pages/ContractSetup";
import { formatNumbers, formatDates, translate, currentLang } from "@/core/helpers/config";
import { useStore } from 'vuex';

export default defineComponent({
  name: "contract-card",
  emits: ['setTempTenant'],
  props: {
    loading: {
      type: Boolean
    },
    enableSort: {
      type: Boolean
    },
    enableFilter: {
      type: Boolean
    },
    contracts: {
      type: [Array, Object]
    },
    sortedContracts: {
      type: [Array, Object]
    }
  },
  components: {
    CardSkeleton
  },
  setup() {
    const handleProjectProgressBadgeStyle = (status: number): string => {
      if (status === 10) return 'badge-light-primary'
      if (status === 50 || status === 80) return 'badge-light-success'
      if (status === 30 || status === 60) return 'badge-light-danger'

      return 'badge-light'
    }

    return {
      formatNumbers,
      formatDates,
      translate,
      currentLang,
      handleProjectProgressBadgeStyle,
      getContractStatusName
    }
  }
})

