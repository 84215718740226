
import { defineComponent, ref } from "vue";
import {
  translate,
} from "@/core/helpers/config";

export default defineComponent({
  name: "AgGridButton",
  props: ['params'],
  setup(props) {
    const value = ref(props.params.node.data);

    const buttonHandler = () => {
      props.params.clicked(props.params)
    }

    return {
      value,
      buttonHandler,
      translate
    };
  },
});
