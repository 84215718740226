<template>
  <router-link
    :to="{ path: `/external/payment_certificate-list`, query: { project: value.project, object: value.object, wp: value.wp } }"
    v-slot="{ navigate }"
    custom
  >
    <span @click="buttonHandler">
      <a href="#" @click="navigate">{{ translate('contractsPage.view') }}</a>
    </span>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  translate,
} from "@/core/helpers/config";

export default defineComponent({
  name: "AgGridButton",
  props: ['params'],
  setup(props) {
    const value = ref(props.params.node.data);

    const buttonHandler = () => {
      props.params.clicked(props.params)
    }

    return {
      value,
      buttonHandler,
      translate
    };
  },
});
</script>

<style scoped>
.btn {
  z-index: 9999;
}
</style>