<template>
  <div class="container-xxl">
    <!--begin::Tab Pane Header-->
    <div class="tab-pane-header mb-8 bg-white rounded p-9 pb-0">
      <div class="d-flex flex-wrap flex-stack">
        <!--begin::Heading-->
        <div class="d-flex flex-wrap align-items-center">
          <h2 class="fw-bolder me-5 my-2 h2">
            {{ translate('contractsPage.contracts') }}
            <!-- <span class="fs-6 text-gray-400 fw-bold ms-1">by Recent Updates ↓</span> -->
          </h2>
          <!-- <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-3 position-absolute ms-3">
            <inline-svg src="media/icons/duotone/General/Search.svg" />
          </span>
          <input
            type="text"
            id="kt_filter_search"
            class="form-control form-control-white form-control-sm w-175px ps-9"
            placeholder="Search"
          />
          </div>-->
        </div>
        <!--end::Heading-->

        <!--begin::Controls-->
        <!-- <div class="d-flex flex-wrap my-1"> -->
        <!--begin:: Sort Select -->
        <!-- <div class="w-175px my-0">
            <select class="form-select form-select-sm" tabindex="-1" @change="handleSort">
              <option value="default">Sort</option>
              <option value="name-asc">Alphabetically, A-Z</option>
              <option value="name-desc">Alphabetically, Z-A</option>
              <option value="budget-asc">Budget, Low to High</option>
              <option value="budget-desc">Budget, High to Low</option>
              <option value="date-asc">Due Date, Old to New</option>
              <option value="date-desc">Due Date, New to Old</option>
            </select>
        </div>-->
        <!--end:: Sort Select -->
        <!-- </div> -->
        <!--end::Controls-->
      </div>

      <!--begin::Filter bar-->
      <div class="d-flex flex-wrap">
        <!-- <div class="d-flex flex-wrap align-items-center">
          <inline-svg src="media/icons/duotone/Shopping/Sort1.svg" />
          <h3 class="fw-bolder me-5 my-2">Filter</h3>
        </div>-->
        <!--begin::Select Status-->
        <!-- <Multiselect
          class="w-175px m-0 me-5 custom__multiselect"
          v-bind="contractStatusMultiselect"
          v-model="contractStatusMultiselect.value"
          tabindex="-1"
        >
          <template v-slot:caret>
            <div class="multiselect-caret custom__caret"></div>
          </template>
          <template v-slot:multiplelabel="{ values }">
            <div class="multiselect-multiple-label">
              Status
              <span class="badge badge-circle badge-info ms-2">{{ values.length }}</span>
            </div>
          </template>
        </Multiselect>-->
        <!--end::Select Status -->

        <!--begin::Select Budget-->
        <!-- <Multiselect
          class="w-175px m-0 me-5 custom__multiselect"
          v-bind="contractBudgetMultiselect"
          v-model="contractBudgetMultiselect.value"
          tabindex="-1"
        >
          <template v-slot:caret>
            <div class="multiselect-caret custom__caret"></div>
          </template>
          <template v-slot:multiplelabel="{ values }">
            <div class="multiselect-multiple-label">
              Budget
              <span class="badge badge-circle badge-info ms-2">{{ values.length }}</span>
            </div>
          </template>
        </Multiselect>-->
        <!--end::Select Budget-->
        <!-- <button class="btn btn-primary btn-sm" @click="handleFilterApply">Apply Filter(s)</button>
        -->
      </div>
      <!--end::Filter bar-->

      <!--begin::Separator-->
      <!-- <div class="separator border-1 my-4"></div> -->
      <!--end::Separator-->

      <!-- <transition name="expand">
      <div v-if="filterArrayValues.length >= 1" class="filter__tagBar">-->
      <!--begin::Filter tag bar-->
      <!-- <div class="d-flex flex-wrap tag__wrapper">
            <ElTag
              v-for="filter in filterArrayValues"
              :key="filter.label"
              closable
              class="me-5 mb-3"
              @close="handleTagClose(filter)"
            >
              <strong>{{ filter.groupLabel }}</strong>
              {{ filter.label }}
            </ElTag>
            <a class="text-decoration-underline pe-auto" style="line-height: 30px;">Clear All</a>
      </div>-->
      <!--end::Filter tag bar-->
      <!-- </div>
      <div v-else class="filter__tagBar">-->
      <!-- <span class="fst-italic">No Filters Applied.</span> -->
      <!-- </div>
      </transition>-->

      <!--begin::Separator-->
      <div class="separator border-1 my-4"></div>
      <!--end::Separator-->

      <div class="d-flex justify-content-between">
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <a
                class="nav-link text-active-primary me-6"
                active-class="active"
                :class="contractTabStatus === 'active' ? 'active' : ''"
                @click="filterAndSave('active')"
              >
                {{ translate('contractsPage.active') }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                  v-if="loading"
                ></span>
                <span
                  v-else
                  class="badge badge-square ms-2"
                  :class="contractTabStatus === 'active' ? 'badge-light' : 'badge-light'"
                >{{ filterStatusCount('active') }}</span>
              </a>
            </li>
            <!--end::Nav item-->

            <!--begin::Nav item-->
            <li class="nav-item">
              <a
                class="nav-link text-active-primary me-6"
                active-class="active"
                :class="contractTabStatus === 'completed' ? 'active' : ''"
                @click="filterAndSave('completed')"
              >
                {{ translate('contractsPage.completed') }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                  v-if="loading"
                ></span>
                <span
                  v-else
                  class="badge badge-square ms-2"
                  :class="contractTabStatus === 'completed' ? 'badge-light' : 'badge-light'"
                >{{ filterStatusCount('completed') }}</span>
              </a>
            </li>
            <!--end::Nav item-->

            <!--begin::Nav item-->
            <li class="nav-item">
              <a
                class="nav-link text-active-primary me-6"
                active-class="active"
                :class="contractTabStatus === 'all' ? 'active' : ''"
                @click="filterAndSave('all')"
              >
                {{ translate('contractsPage.all') }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                  v-if="loading"
                ></span>
                <span
                  v-else
                  class="badge badge-square ms-2"
                  :class="contractTabStatus === 'all' ? 'badge-light' : 'badge-light'"
                >{{ filterStatusCount('all') }}</span>
              </a>
            </li>
            <!--end::Nav item-->
          </ul>
        </div>

        <ul class="nav nav-pills me-5">
          <li class="nav-item m-0">
            <a
              class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3"
              title="Card View"
              data-bs-toggle="tab"
              href="#kt_project_targets_card_pane"
              :class="currentView === 'card' ? 'active' : ''"
              @click="setView('card')"
            >
              <!--begin::Svg Icon | path: icons/duotune/general/gen024.svg-->
              <span class="svg-icon svg-icon-2">
                <inline-svg src="media/icons/duotone/Layout/Layout-4-blocks.svg" />
              </span>
              <!--end::Svg Icon-->
            </a>
          </li>
          <li class="nav-item m-0">
            <a
              class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary"
              data-bs-toggle="tab"
              href="#kt_project_targets_table_pane"
              :class="currentView === 'table' ? 'active' : ''"
              @click="setView('table')"
            >
              <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
              <span class="svg-icon svg-icon-2">
                <inline-svg src="media/icons/duotone/Layout/Layout-horizontal-2.svg" />
              </span>
              <!--end::Svg Icon-->
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!--end::Tab Pane Header-->

    <!--begin::Tab Pane Content-->
    <div class="tab-content">
      <!--begin::Tab pane-->
      <div
        id="kt_project_targets_card_pane"
        class="tab-pane fade"
        :class="currentView === 'card' ? 'active show' : ''"
      >
        <h1
          class="display-1 text-center"
          style="font-size:3.5rem;font-weight:100;padding-top:120px;"
          v-if="contracts && Object.keys(contracts).length === 0"
        >{{ translate('contractsPage.noContracts') }}</h1>
        <CardComponent
          v-else
          :loading="loading"
          :enable-sort="enableSort"
          :enable-filter="enableFilter"
          :contracts="groupedContracts"
          :sorted-contracts="sortedContracts"
          @set-temp-tenant="setTempTenant"
        />
      </div>
      <!--end::Tab pane-->

      <!--begin::Tab pane-->
      <div
        id="kt_project_targets_table_pane"
        class="tab-pane fade"
        :class="currentView === 'table' ? 'active show' : ''"
      >
        <div class="card card-flush">
          <div class="card-body pt-3">
            <TableSkeleton v-show="loading" />
            <AgGridFP
              v-show="!loading"
              id="table1"
              ag-theme="ag-theme-material"
              widget-classes="card-xxl-stretch mb-xl-3"
              @displayedColumnsChanged="handleSaveState"
              :enable-card-header="true"
              :aggrid-data="unGroupedContracts"
              :custom-column-defs="customColumnDefs"
              :custom-body-options="bodyOptions"
              :enable-row-group="true"
              :enableStatusBar="true"
            ></AgGridFP>
          </div>
        </div>
      </div>
      <!--end::Tab pane-->
    </div>
    <!--end::Tab Pane Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onMounted, ref, computed } from "vue";
// import { ElTag } from 'element-plus';
// import Multiselect from '@vueform/multiselect';
import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import CardComponent from "@/components/contract/Card.vue"
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";
import { ContractSetup } from "./ContractSetup";
import { ContractTableSetup } from "./ContractTableSetup";
import { translate } from "@/core/helpers/config";
import { useDebounceFn } from "@vueuse/core";
import { saveState } from "@/core/helpers/aggrid";
import { useStore } from "vuex";
import { Actions as AggridActions } from "@/store/enums/AggridEnums";


enum State {
  tableName = "table_name",
  userId = "user_id",
}

export default defineComponent({
  name: "my-contracts",
  components: {
    // Multiselect,
    // ElTag,
    AgGridFP,
    CardComponent,
    TableSkeleton
  },
  setup() {
    const store = useStore();
    const currentView = ref('card');
    const columnState = computed(() => {
      return store.getters.getStates["external-contracts"]
    });

    const {
      filterStatus,
      contracts,
      filterStatusCount,
      contractTabStatus,
      unGroupedContracts,
      selectedSortOption,
      selectedFilterOption,
      handleSort,
      handleFilterApply,
      groupedContracts,
      sortedContracts,
      filteredContracts,
      contractStatusMultiselect,
      contractBudgetMultiselect,
      filterArrayValues,
      enableFilter,
      enableSort,
      handleTagClose,
      setTempTenant,
    } = ContractSetup();

    const {
      gridApi,
      colApi,
      loading,
      bodyOptions,
      customColumnDefs,
    } = ContractTableSetup(unGroupedContracts.value);

    const handleSaveState = useDebounceFn(() => {
      if (unGroupedContracts.value.length === 0) return;
      const state = {
        view: currentView.value,
        cardState: contractTabStatus.value,
        tableState: colApi.value!.getColumnState()
      }

      const newState = {
        state: state,
        [State.tableName]: "external-contracts",
      };
      saveState(newState);
    }, 1000);

    const filterAndSave = (status: string): void => {
      filterStatus(status);
      handleSaveState()
    }

    const setView = (view: string): void => {
      currentView.value = view
      handleSaveState()
    }

    const applyState = useDebounceFn(() => {
      const state = columnState.value['external-contracts']["tableState"];
      setView(columnState.value['external-contracts']['view'] || 'card');
      filterStatus(columnState.value['external-contracts']['cardState'] || 'active');

      colApi.value!.applyColumnState({
        state: state,
        applyOrder: true,
      });
    }, 1000);

    onBeforeMount(() => {
      store.dispatch(AggridActions.GET_TABLESTATE_ACTION, "external-contracts");
    });

    onMounted(() => {
      applyState();
    });

    return {
      filterAndSave,
      currentView,
      setView,
      loading,
      contracts,
      filterStatusCount,
      contractTabStatus,
      unGroupedContracts,
      selectedSortOption,
      selectedFilterOption,
      handleSort,
      handleFilterApply,
      groupedContracts,
      sortedContracts,
      filteredContracts,
      contractStatusMultiselect,
      contractBudgetMultiselect,
      filterArrayValues,
      enableFilter,
      enableSort,
      handleTagClose,
      setTempTenant,
      translate,
      gridApi,
      colApi,
      columnState,
      bodyOptions,
      customColumnDefs,
      handleSaveState,
      filterStatus
    };
  },
});
</script>

<style>
.nav-item {
  cursor: pointer;
}

.filter__tagBar {
  height: auto;
  animation: slideDown 0.2s linear;
  overflow: hidden;
}

.expand-leave-active.expand-leave-to {
  transition: height 0.2s ease;
  height: 0;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: 50px;
  }
}
</style>
