<template>
  <div class="row g-6 g-xl-9 mt-5" v-if="loading" key="1">
    <CardSkeleton />
  </div>
  <transition name="fade" v-else key="2">
    <div>
      <div
        class="row g-6 g-xl-9 mt-5"
        v-for="(contractArray, groupName) in contracts"
        :key="groupName"
      >
        <div class="col-12">
          <div class="d-flex align-items-center">
            <img
              class="rounded img-thumbnail"
              :src="`media/svg/brand-logos/${contractArray[0]['companyName'].toLowerCase()}.svg`"
              onerror="javascript:this.src='media/svg/brand-logos/No_image_available.svg'"
              width="40"
            />
            <h2 class="display-6 mb-0 ms-3">{{ contractArray[0]['companyName'] }}</h2>
          </div>
          <!--begin::Separator-->
          <div class="separator separator-dashed border-dark my-0"></div>
          <!--end::Separator-->
        </div>
        <!--begin::Col-->
        <div v-if="contractArray.length === 0">
          <h1 class="display-1 text-center">No Results Found.</h1>
        </div>
        <transition-group name="flip-list" class="row g-6 g-xl-9 mt-5" tag="div" v-else>
          <div
            class="col-md-6 col-xl-4 flip-list-item"
            v-for="contract in contractArray"
            :key="contract.doc_no"
            @click="$emit('setTempTenant', groupName)"
          >
            <!--begin::Card-->
            <router-link
              :class="contract.actsInProgressCount > 0 ? 'in-progress' : ''"
              :to="{ path: `/external/payment_certificate-list`, query: { project: contract.project, object: contract.object, wp: contract.wp } }"
              class="card border-hover-primary"
            >
              <!--begin::Card header-->

              <div class="card-header border-0 pt-9">
                <div class="card-title w-100 mb-9">
                  <div class="d-flex flex-row justify-content-between flex-fill">
                    <div class="symbol p-3 bg-light text-center w-150px">
                      <div class="fs-2 fw-bold text-success contract-no">
                        <span
                          v-if="contract.doc_no == ''"
                        >{{ contract.contract_no == '' ? contract.wp : contract.contract_no }}</span>
                        <span v-else>{{ contract.doc_no }}</span>
                      </div>
                    </div>

                    <span
                      v-if="contract.actsInProgressCount > 0"
                      class="fw-bolder text-capitalize pill-rounded badge-pill"
                    >
                      <span
                        href="#"
                        class="btn btn-icon btn-light pulse pulse-danger"
                        title="Acts In Progress"
                      >
                        <span class="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z"
                              fill="black"
                            />
                            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                          </svg>
                        </span>
                        <span class="pulse-ring pulse-danger"></span>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-content-center flex-fill">
                  <span
                    class="badge"
                    :class="handleProjectProgressBadgeStyle(contract.status)"
                  >{{ translate(getContractStatusName(contract.status)) }}</span>
                </div>
              </div>
              <!--end:: Card header-->
              <!--begin:: Card body-->
              <div class="card-body p-9 pt-4">
                <!--begin::Name-->
                <div class="fs-3 fw-bolder text-dark contract-name">{{ contract.name }}</div>
                <!--end::Name-->
                <!--begin::Description-->
                <p class="text-gray-400 fw-bold fs-5 mt-1 mb-7">{{ contract.project_name }}</p>
                <!--end::Description-->
                <!--begin::Info-->
                <div class="d-flex flex-wrap mb-5">
                  <!--begin::Due-->
                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3"
                  >
                    <div
                      class="fs-6 text-gray-800 fw-bolder"
                    >{{ contract.cost_end !== '0000-00-00' ? formatDates(contract.cost_end, "short", currentLang) : 'Pending' }}</div>
                    <div class="fw-bold text-gray-400">{{ translate('contractsPage.dueDate') }}</div>
                  </div>
                  <!--end::Due-->
                  <!--begin::Budget-->
                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3"
                  >
                    <div
                      class="fs-6 text-gray-800 fw-bolder"
                    >{{ formatNumbers(contract.contract_budget) }}</div>
                    <div class="fw-bold text-gray-400">{{ translate('contractsPage.budget') }}</div>
                  </div>
                  <!--end::Budget-->
                </div>
                <!--end::Info-->
              </div>
              <!--end:: Card body-->
            </router-link>
            <!--end::Card-->
          </div>
        </transition-group>

        <!--end::Col-->
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import CardSkeleton from "@/components/skeletons/CardSkeleton.vue"
import { getContractStatusName } from "@/views/contractor/pages/ContractSetup";
import { formatNumbers, formatDates, translate, currentLang } from "@/core/helpers/config";
import { useStore } from 'vuex';

export default defineComponent({
  name: "contract-card",
  emits: ['setTempTenant'],
  props: {
    loading: {
      type: Boolean
    },
    enableSort: {
      type: Boolean
    },
    enableFilter: {
      type: Boolean
    },
    contracts: {
      type: [Array, Object]
    },
    sortedContracts: {
      type: [Array, Object]
    }
  },
  components: {
    CardSkeleton
  },
  setup() {
    const handleProjectProgressBadgeStyle = (status: number): string => {
      if (status === 10) return 'badge-light-primary'
      if (status === 50 || status === 80) return 'badge-light-success'
      if (status === 30 || status === 60) return 'badge-light-danger'

      return 'badge-light'
    }

    return {
      formatNumbers,
      formatDates,
      translate,
      currentLang,
      handleProjectProgressBadgeStyle,
      getContractStatusName
    }
  }
})

</script>

<style scoped>
.card {
  min-height: 323.69px;
}
.act-badge {
  font-size: 9px;
  left: 52%;
  color: #f64e60 !important;
  font-weight: bold;
  background-color: #fcbfc6;
  padding: 5px;
  padding-left: 7px;
  padding-right: 8px;
}

.card > .card-header .card-title {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
/* .in-progress {
  box-shadow: 8px -7px 19px -10px #fd6100;
  border: 1px solid #ffc700 !important;
} */

.contract-no {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.contract-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.flip-list-item {
  transition: all 1s;
}
.flip-list-enter, .flip-list-leave-to
/* .flip-list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.flip-list-leave-active {
  position: absolute;
}
</style>
