import CONTRACTS from "@/assets/json/projects.json";

import { ref, onMounted, computed, reactive, watchEffect } from "vue";
import { groupBy, translate } from "@/core/helpers/config";

import { useStore } from "vuex";
import { Contract } from "@/store/interface/ContractorInterfaces";
import { Actions } from "@/store/enums/ContractorEnums";

import CookieService from "@/core/services/CookieService";
import { gridApi } from "./ContractTableSetup";

interface GroupContracts {
  [key: string]: Contract[];
}

interface DropdownValues {
  value?: string | number;
  label?: string;
  groupLabel?: string;
}

export const getContractStatusName = (status: number) => {
  switch (status) {
    case 5:
      return "contractsPage.sentToPOM";
    case 10:
      return "contractsPage.progress";
    case 20:
      return "contractsPage.confirmed";
    case 30:
      return "contractsPage.closed";
    case 40:
      return "contractsPage.sent";
    case 50:
      return "contractsPage.completed";
    case 60:
      return "contractsPage.cancelled";
    case 70:
      return "contractsPage.ordered";
    case 80:
      return "contractsPage.delivered";
    default:
      return "contractsPage.initial";
  }
};

export const setTempTenant = (tenant: string): void => {
  const tenantNoCompanyName = tenant.split(":")[0];
  CookieService.saveCookie(tenantNoCompanyName, "X-TEMP-TENANT");
};

export const ContractSetup = () => {
  const store = useStore();
  const filteredContracts = ref();
  const contractTabStatus = ref("active");
  const contracts = ref<any[]>([]);
  const unGroupedContracts = ref<any[]>([]);
  const selectedSortOption = ref("default");
  const selectedFilterOption = ref("default");
  const enableFilter = ref<boolean>(false);
  const enableSort = ref<boolean>(false);
  const activeArray = [0, 10, 20];
  const completedArray = [5, 30, 40, 50, 60, 70, 80];

  const contractStatusMultiselect = reactive({
    mode: "multiple",
    placeholder: "Status",
    closeOnSelect: false,
    object: true,
    value: [] as DropdownValues[],
    options: [] as DropdownValues[],
    trackBy: "value",
    label: "label",
  });
  const contractBudgetMultiselect = reactive({
    mode: "multiple",
    placeholder: "Budget",
    closeOnSelect: false,
    object: true,
    value: [] as DropdownValues[],
    options: [
      {
        value: [10000],
        label: "Less than $10000",
        groupLabel: "contract_budget",
      },
      {
        value: [10000, 30000],
        label: "$10000 - $30000",
        groupLabel: "contract_budget",
      },
      {
        value: [30000, 60000],
        label: "$30000 - $60000",
        groupLabel: "contract_budget",
      },
      {
        value: [60000, 90000],
        label: "$60000 - $90000",
        groupLabel: "contract_budget",
      },
      {
        value: [90000],
        label: "Greater than $90000",
        groupLabel: "contract_budget",
      },
    ],
    trackBy: "value",
    label: "label",
  });
  const unGroupContracts = () => {
    // UngroupAdd tenant property to all contract objects
    if (!contracts.value) return [];

    const array = [] as Contract[];

    for (const key of Object.keys(contracts.value)) {
      const value = contracts.value[key];
      for (let i = 0; i < value.length; i++) {
        value[i]["tenant"] = key;
        array.push(value[i]);
      }
    }
    return array;
  };

  const filterArrayValues = computed(() => {
    return Array.prototype.concat(
      contractStatusMultiselect.value,
      contractBudgetMultiselect.value
    );
  });

  const setStatusMultiSelect = (): void => {
    const getAllAvailableStatuses = unGroupedContracts.value.map(
      (v) => v.contract_status
    );
    const removeDupeStatuses = [...new Set(getAllAvailableStatuses)];
    const filterStatusValues = removeDupeStatuses.map((status) => ({
      value: status,
      label: translate(getContractStatusName(status)),
      groupLabel: "contract_status",
    }));
    contractStatusMultiselect.options = filterStatusValues;
  };

  const handleSort = (evt) => {
    selectedSortOption.value = evt.target.value;
  };

  const handleFilterAgGridTable = (statusValues?) => {
    console.log(statusValues);
    // const statusValues = filterArrayValues.value
    //   .map((x) => {
    //     if (x.groupLabel === "contract_status") return x.value;
    //   })
    //   .map(String);

    if (!statusValues) {
      gridApi.value!.destroyFilter("status");
    }

    const instance = gridApi.value!.getFilterInstance("status");
    instance!.setModel({
      values: statusValues,
    });
    gridApi.value!.onFilterChanged();
  };

  const filterStatus = (status?: string) => {
    if (status === "active") {
      contractTabStatus.value = "active";
      handleFilterAgGridTable(activeArray);
      unGroupedContracts.value = contracts.value.filter((contract) =>
        activeArray.includes(contract["status"])
      );
      setStatusMultiSelect();
    } else if (status === "completed") {
      contractTabStatus.value = "completed";
      handleFilterAgGridTable(completedArray);
      unGroupedContracts.value = contracts.value.filter((contract) =>
        completedArray.includes(contract["status"])
      );
      setStatusMultiSelect();
    } else {
      contractTabStatus.value = "all";
      unGroupedContracts.value = contracts.value;
      setStatusMultiSelect();
    }
  };

  const filterStatusCount = (status?: string): number => {
    if (status === "active") {
      return contracts.value.filter((contract) =>
        activeArray.includes(contract["status"])
      ).length;
    } else if (status === "completed") {
      return contracts.value.filter((contract) =>
        completedArray.includes(contract["status"])
      ).length;
    } else {
      return contracts.value.length;
    }
  };

  const handleFilterApply = () => {
    if (filterArrayValues.value.length === 0) {
      return unGroupedContracts.value;
    }

    const groupLabel = filterArrayValues.value.map((x) => x.groupLabel);
    const groupLabelNoDupes = [...new Set(groupLabel)];
    let contractsCopy;
    let temp;

    groupLabelNoDupes.forEach((obj) => {
      if (obj === "contract_status") {
        contractsCopy = unGroupedContracts.value.filter((contract) =>
          contractStatusMultiselect.value
            .map((obj) => obj.value)
            .includes(contract[obj])
        );
      } else if (obj === "contract_budget") {
        filterArrayValues.value.forEach((minMaxObj) => {
          const min = minMaxObj.value[0];
          const max = minMaxObj.value[1];

          if (min === 10000 && !max) {
            temp = unGroupedContracts.value.filter(
              (contract) => contract[obj] <= min
            );
          } else if (min === 90000) {
            temp = unGroupedContracts.value.filter(
              (contract) => contract[obj] >= min
            );
          } else {
            temp = unGroupedContracts.value.filter(
              (contract) => contract[obj] >= min && contract[obj] <= max
            );
          }
        });
      }
    });
    return contractsCopy;
  };

  const groupedContracts = computed(() => {
    return groupBy(unGroupedContracts.value, "tenantName");
    // return contracts.value;
  });

  const handleTagClose = (tag) => {
    filterArrayValues.value.splice(filterArrayValues.value.indexOf(tag), 1);
    if (
      contractStatusMultiselect.value.length > 0 &&
      contractStatusMultiselect.value.indexOf(tag) !== -1
    ) {
      contractStatusMultiselect.value.splice(
        contractStatusMultiselect.value.indexOf(tag),
        1
      );
      filteredContracts.value = CONTRACTS.slice();
      handleFilterApply();

      return;
    }
    if (
      contractBudgetMultiselect.value.length > 0 &&
      contractBudgetMultiselect.value.indexOf(tag) !== -1
    ) {
      contractBudgetMultiselect.value.splice(
        contractBudgetMultiselect.value.indexOf(tag),
        1
      );
      filteredContracts.value = CONTRACTS.slice();

      handleFilterApply();
      return;
    }
  };

  const sortedContracts = computed(() => {
    if (selectedSortOption.value === "default" && !enableFilter.value) return;
    const selectedSort = selectedSortOption.value.split("-");
    if (selectedSort[0] === "budget") {
      return filteredContracts.value.slice().sort((a, b) => {
        if (selectedSort[1] === "asc")
          return a.contract_budget - b.contract_budget;
        return b.contract_budget - a.contract_budget;
      });
    } else if (selectedSort[0] === "date") {
      return filteredContracts.value.slice().sort((a, b) => {
        if (selectedSort[1] === "asc")
          return (
            new Date(a.delivery_date).valueOf() -
            new Date(b.delivery_date).valueOf()
          );
        return (
          new Date(b.delivery_date).valueOf() -
          new Date(a.delivery_date).valueOf()
        );
      });
    } else {
      return filteredContracts.value.slice().sort((a, b) => {
        if (selectedSort[1] === "asc") return a.name.localeCompare(b.name);
        return b.name.localeCompare(a.name);
      });
    }
  });

  watchEffect(() => {
    if (filterArrayValues.value.length === 0) {
      enableFilter.value = false;
      filteredContracts.value = CONTRACTS.slice();
    }
    if (selectedSortOption.value === "default") {
      enableSort.value = false;
    } else {
      enableSort.value = true;
    }
  });

  onMounted(() => {
    setTimeout(() => {
      CookieService.destroyCookie("X-TEMP-TENANT");
      // contracts.value = groupBy(CONTRACTS, "tenant");
      store
        .dispatch(Actions.READ_USER_CONTRACT, { ungrouped: 1 })
        .then((data) => {
          // Check to see if user has contracts
          if (Object.prototype.hasOwnProperty.call(data, "message")) {
            contracts.value = [];
            return;
          }
          // Set initial status filter [all, active, completed]
          unGroupedContracts.value = data;
          contracts.value = data;
          // unGroupedContracts.value = unGroupContracts();
        });
    }, 1000);
  });

  return {
    contracts,
    filterStatus,
    filterStatusCount,
    contractTabStatus,
    unGroupedContracts,
    selectedSortOption,
    selectedFilterOption,
    handleSort,
    handleFilterApply,
    groupedContracts,
    sortedContracts,
    filteredContracts,
    contractStatusMultiselect,
    contractBudgetMultiselect,
    filterArrayValues,
    enableFilter,
    enableSort,
    handleTagClose,
    setTempTenant,
  };
};
