
import { defineComponent, onBeforeMount, onMounted, ref, computed } from "vue";
// import { ElTag } from 'element-plus';
// import Multiselect from '@vueform/multiselect';
import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import CardComponent from "@/components/contract/Card.vue"
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";
import { ContractSetup } from "./ContractSetup";
import { ContractTableSetup } from "./ContractTableSetup";
import { translate } from "@/core/helpers/config";
import { useDebounceFn } from "@vueuse/core";
import { saveState } from "@/core/helpers/aggrid";
import { useStore } from "vuex";
import { Actions as AggridActions } from "@/store/enums/AggridEnums";


enum State {
  tableName = "table_name",
  userId = "user_id",
}

export default defineComponent({
  name: "my-contracts",
  components: {
    // Multiselect,
    // ElTag,
    AgGridFP,
    CardComponent,
    TableSkeleton
  },
  setup() {
    const store = useStore();
    const currentView = ref('card');
    const columnState = computed(() => {
      return store.getters.getStates["external-contracts"]
    });

    const {
      filterStatus,
      contracts,
      filterStatusCount,
      contractTabStatus,
      unGroupedContracts,
      selectedSortOption,
      selectedFilterOption,
      handleSort,
      handleFilterApply,
      groupedContracts,
      sortedContracts,
      filteredContracts,
      contractStatusMultiselect,
      contractBudgetMultiselect,
      filterArrayValues,
      enableFilter,
      enableSort,
      handleTagClose,
      setTempTenant,
    } = ContractSetup();

    const {
      gridApi,
      colApi,
      loading,
      bodyOptions,
      customColumnDefs,
    } = ContractTableSetup(unGroupedContracts.value);

    const handleSaveState = useDebounceFn(() => {
      if (unGroupedContracts.value.length === 0) return;
      const state = {
        view: currentView.value,
        cardState: contractTabStatus.value,
        tableState: colApi.value!.getColumnState()
      }

      const newState = {
        state: state,
        [State.tableName]: "external-contracts",
      };
      saveState(newState);
    }, 1000);

    const filterAndSave = (status: string): void => {
      filterStatus(status);
      handleSaveState()
    }

    const setView = (view: string): void => {
      currentView.value = view
      handleSaveState()
    }

    const applyState = useDebounceFn(() => {
      const state = columnState.value['external-contracts']["tableState"];
      setView(columnState.value['external-contracts']['view'] || 'card');
      filterStatus(columnState.value['external-contracts']['cardState'] || 'active');

      colApi.value!.applyColumnState({
        state: state,
        applyOrder: true,
      });
    }, 1000);

    onBeforeMount(() => {
      store.dispatch(AggridActions.GET_TABLESTATE_ACTION, "external-contracts");
    });

    onMounted(() => {
      applyState();
    });

    return {
      filterAndSave,
      currentView,
      setView,
      loading,
      contracts,
      filterStatusCount,
      contractTabStatus,
      unGroupedContracts,
      selectedSortOption,
      selectedFilterOption,
      handleSort,
      handleFilterApply,
      groupedContracts,
      sortedContracts,
      filteredContracts,
      contractStatusMultiselect,
      contractBudgetMultiselect,
      filterArrayValues,
      enableFilter,
      enableSort,
      handleTagClose,
      setTempTenant,
      translate,
      gridApi,
      colApi,
      columnState,
      bodyOptions,
      customColumnDefs,
      handleSaveState,
      filterStatus
    };
  },
});
